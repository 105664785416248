var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasErrors
    ? _c(
        "ul",
        {
          staticClass: "field-errors",
          attrs: { role: "alert", "aria-live": "polite" }
        },
        [_c("li", [_vm._t("default")], 2)]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }